import React from "react";
import PaymentBase from "../PaymentBase";
import {LeftButton} from "../../buttons/LeftButton";
import Select from "react-select";

export default class SelectAmountOfInstallmentsStep extends PaymentBase{

    constructor(props) {
        super(props);
        this._allowedInstallments = this.props.numberOfInstallmentsAllowed.filter((number_of_installments) => number_of_installments !== 1);
        this.installmentsOptions = this._installmentsOptions();
        this.state = {
            amountOfInstallments: this._allowedInstallments[0]
        }
        this.onFinishDo = this.onFinishDo.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
    }

    _installmentsOptions(){
        return this._allowedInstallments.map((number_of_installments) => {
            let installmentsWithoutInitialPayment = number_of_installments - 1;
            let installmentsLabel = installmentsWithoutInitialPayment === 1 ? 'cuota' : 'cuotas';
            let label = `${number_of_installments} Cuotas (Anticipo + ${installmentsWithoutInitialPayment} ${installmentsLabel})`;
            if (this._hasTwoOrThreeInstallments(number_of_installments)) {
                label += " - Mismo valor en cuotas";
            }
            // Por ahora deshabilitamos esto (cambio por DNU e introduccion de cuotas con intereses)
            // if (this._hasTwoOrThreeInstallmentsDiscount(number_of_installments)) {
            //     label += ` - Incluye descuento ${this.props.warrantyInformation.twoOrThreeInstallmentsDiscount}%`
            // }
            return {
                value: number_of_installments,
                label: label
            }
        });
    }

    _twoOrThreeInstallmentsDiscount(discountPercentage) {
        /*if (this._hasTwoOrThreeInstallments(this.state.amountOfInstallments)) {
            return this._discount(discountPercentage)
        }*/
        return this._discount(discountPercentage)
    }

    _hasTwoOrThreeInstallments(installmentsAmount) {
        return installmentsAmount === 2 || installmentsAmount === 3;
    }

    title() {
        return <h1>Pago en Cuotas</h1>
    }

    handleSelectChange(e) {
        this.setState({ amountOfInstallments: e.value });
    }

    body() {
        const installmentsOptions = this.installmentsOptions;
        return (
            <div className="middle-bar-content payment-bar installments-bar">
                {this._amountToPay('Garantía Finaer')}
                <Select labelId="label"
                        onChange={this.handleSelectChange}
                        id="select-installments"
                        defaultValue={installmentsOptions[0]}
                        options={installmentsOptions}
                        className='select-installments'
                />
                {this._twoOrThreeInstallmentsDiscount(this.props.warrantyInformation.twoOrThreeInstallmentsDiscount)}
                <p className="interest-rates-message">
                    {this._interestRatesAccordingTo(this.state.amountOfInstallments)}
                </p>
            </div>
        );
    }

    _interestRatesAccordingTo(amountOfInstallments) {
        let interestRatesText =  "Costo Financiero Total : CFT 0%"
        if (amountOfInstallments === 4) {
            interestRatesText = "TNA: 58.02% - TEA: 74.90% - CTF: 78.65%"
        }
        if (amountOfInstallments === 5) {
            interestRatesText = "TNA: 43.26% - TEA: 52.09% - CTF: 55.09%"
        }
        if (amountOfInstallments === 6) {
            interestRatesText = "TNA: 34.79% - TEA: 39.85% - CTF: 43.60%"
        }
        let noInterestText = amountOfInstallments <= 3 ? 'sin interés' : ''
        let equalInstallmentsText = amountOfInstallments <= 3 ? 'iguales y' : ''
        return <> {interestRatesText} - Cuotas mensuales {equalInstallmentsText} fijas {noInterestText} </>
    }

    onFinishDo(){
        this.props.handleAmountOfInstallmentsSelected(this.state.amountOfInstallments);
    }

    footer() {
        return (
            <div className="arrows-payment">
                <LeftButton onClick={this.props.handlePrevious}/>
                <button onClick={this.onFinishDo} className={"main-btn confirm-payment-btn"}>Siguiente</button>
            </div>
        );
    }
}